'use strict';

(function() {
  class assignContactSiteCtrl {
    constructor($uibModalInstance, options, Site, Contact, Util, usSpinnerService) {
        this.Util = Util;
        this.$uibModalInstance = $uibModalInstance;
        this.usSpinnerService = usSpinnerService;
        this.isDisabled = false;
        this.errors = {};
        this.options = options;
        this.submitted = false;
        this.Contact = Contact;
        this.Site = Site;
        if (this.options) {
            this.options = angular.copy(this.options);
        }
        this.init();
    }

    init() {
        this.Contact.get({ organization: this.options.organization.id })
            .$promise.then((response) => {
                if (response.data) {
                  this.contacts = response.data.sort((a,b) => a.firstName.localeCompare(b.firstName));
                }
            })
        .catch((err) => {
            console.log(err);
        });
    }

    assignContactToSite(form) {
        this.submitted = true;
        this.serverError = null;
        if (form.$valid) {
            this.isDisabled = true;
            this.startSpin('spinner-1');
            console.log('this.assignContactSite', this.assignContactSite);
            let site = this.options.site;
            site.primaryContact = this.assignContactSite.contact;
            this.Site.update(site).$promise
            .then(site => {
                this.stopSpin('spinner-1');
                this.$uibModalInstance.close(site);
            })
            .catch(err => {
                this.isDisabled = false;
                this.errors = {};
                this.stopSpin('spinner-1');
                this.handleError(err, form);
            });
        }
    }

    handleError(err, form) {
        if (err && err.data && err.data.meta && err.data.meta.error_message) {
          if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
            this.serverError = err.data.meta.error_message || 'Internal Server Error';
          } else {
            err.data.meta.error_message.forEach(errorMesssage => {
              for (let fieldName in errorMesssage) {
                if (form[fieldName]) {
                  form[fieldName].$setValidity('mongoose', false);
                  this.errors[fieldName] = errorMesssage[fieldName];
                }
              }
            })
          }
        } else if (err.data && err.data.message) {
          this.serverError = err.data.message;  
          console.log('validation err: 1', err);
        } else {
          this.serverError = 'Internal Server Error';
          console.log('validation err: 2', err);
        }
      }

    cancelSave() {
      this.$uibModalInstance.dismiss('cancel');
    }
    startSpin(spinner) {
        this.usSpinnerService.spin(spinner);
      }
  
      stopSpin(spinner) {
        this.usSpinnerService.stop(spinner);
      }
  }
  angular.module('windmanagerApp')
    .controller('assignContactSiteCtrl', assignContactSiteCtrl);
})();
